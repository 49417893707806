import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../state/auth/auth.service';

export const authGuard: CanActivateFn = async (_route, _state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const user = await authService.user;
  if (!user) {
    return router.parseUrl('/');
  }
  return true;
};

export const adminGuard: CanActivateFn = async (_route, _state) => {
  const authService = inject(AuthService);

  const user = await authService.user;
  return !!user?.isAdmin;
};

export const reverseAuthGuard: CanActivateFn = async (_route, _state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const user = await authService.user;
  if (user) {
    return router.parseUrl('/home');
  }
  return true;
};

export const autoLoginGuard: CanActivateFn = async (_route, _state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const user = await authService.user;
  if (!user) {
    authService.autoSignIn();
  } else {
    return router.parseUrl('/home');
  }
  return true;
};
