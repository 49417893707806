<div>
  <div class="teaser">
    <h1>Innovate Farming Together</h1>
    <p>Join us in innovating farming: Explore and <span class="highlight">influence</span> our upcoming ideas</p>
    <app-button variant="tertiary" size="large" (buttonClick)="doSignUp()">Get started</app-button>
  </div>
  <div class="steps page-wrapper">
    <div>
      <div class="step">
        <app-bullet size="small">1</app-bullet>
        <h3>Sign up for free</h3>
        <p>It's easy to get started</p>
      </div>
      <div class="step">
        <app-bullet size="small">2</app-bullet>
        <h3>Discover and explore</h3>
        <p>Dive into the features and ideas you find interesting</p>
      </div>
      <div class="step">
        <app-bullet size="small">3</app-bullet>
        <h3>Share your thoughts and feedback</h3>
        <p>We value your input, it guides our direction</p>
      </div>
      <p>
        When you share your feedback with us, you nurture our ideas, allowing them to grow into valuable features and
        products. By collecting your feedback, we ensure that our ideas develop in ways that meet our users' needs and
        support them in their daily work.
      </p>
    </div>
    <img src="images/landing-steps.png" alt="steps" />
  </div>
  <div class="features">
    <div class="page-wrapper">
      <h3>Explore our features</h3>
      <p>
        Discover ready-to-use features alongside early-stage ideas and prototypes. We share new concepts to gather your
        feedback, ensuring we meet your needs and deliver value. Your input helps us improve and guides our future
        development.
      </p>
      <div class="cards">
        <div class="card">
          <img src="images/landing-geo-bird.png" alt="Geo-Bird" />
          <h4>Geo-Bird - Wayline management</h4>
          <p class="with-image">
            Create optimized waylines in just a few easy steps and use them in your fields.
            <img src="icons/geo-bird.svg" alt="Geo-Bird" />
          </p>
        </div>
        <div class="card">
          <img src="images/landing-slurry.png" alt="Slurry Optimization" />
          <h4>Slurry Optimization</h4>
          <p>Minimize unnecessary driving in the field with optimized routes for slurry applications.</p>
        </div>
        <div class="card">
          <img src="images/landing-soil-compaction.png" alt="Soil Compaction" />
          <h4>Soil Compaction</h4>
          <p>Reduce soil compaction with intelligent route planning.</p>
        </div>
      </div>
      <p class="more">... and other innovative features and ideas</p>
    </div>
  </div>
  <div class="cta-section page-wrapper">
    <div>
      <h4>Get started today</h4>
      <p>Let's innovate farming together!</p>
    </div>
    <app-button variant="tertiary-inverted" (buttonClick)="doSignUp()">Sign up</app-button>
  </div>
  <div class="helper">
    <h5>Need help?</h5>
    <p>
      Send an e-mail to
      <app-link variant="basic" href="mailto:support@agco-innovationhub.com"
        >support&#64;agco-innovationhub.com</app-link
      >
    </p>
  </div>
</div>
