import { Routes } from '@angular/router';
import { LandingPageComponent } from '../pages/landing-page/landing-page.component';
import { HomePageComponent } from '../pages/home-page/home-page.component';
import { adminGuard, authGuard, reverseAuthGuard, autoLoginGuard } from './auth.guard';
import { SchedulingPageComponent } from '../pages/scheduling-page/scheduling-page.component';
import { SlurryOptimizationPageComponent } from '../pages/slurry-optimization-page/slurry-optimization-page.component';
import { SolgrasPageComponent } from '../pages/solgras-page/solgras-page.component';
import { AdminPageComponent } from '../pages/admin-page/admin-page.component';
import { OSMPageComponent } from '../pages/osm-page/osm-page.component';
import { NotFoundPageComponent } from '../pages/not-found-page/not-found-page.component';
import { FeedbackPageComponent } from '../pages/admin-page/feedback-page/feedback-page.component';

export const routes: Routes = [
  {
    path: 'auto-login',
    component: HomePageComponent,
    canActivate: [autoLoginGuard],
  },
  {
    path: 'scheduling',
    component: SchedulingPageComponent,
    canActivate: [authGuard],
  },
  {
    path: 'slurry-optimization',
    component: SlurryOptimizationPageComponent,
    canActivate: [authGuard],
  },
  {
    path: 'solgras',
    component: SolgrasPageComponent,
    canActivate: [authGuard],
  },
  {
    path: 'osm',
    component: OSMPageComponent,
    canActivate: [authGuard],
  },
  { path: 'home', component: HomePageComponent, canActivate: [authGuard] },
  { path: 'admin', component: AdminPageComponent, canActivate: [adminGuard] },
  { path: 'admin/feedback/:feature', component: FeedbackPageComponent, canActivate: [adminGuard] },
  { path: 'admin/feedback', redirectTo: 'admin' },
  {
    path: '',
    component: LandingPageComponent,
    pathMatch: 'full',
    canActivate: [reverseAuthGuard],
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];
