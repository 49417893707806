<div class="header"><h2>Welcome</h2></div>
<p>
  Here you will find ideas and features, we're working on, but haven't released yet. We share new ideas and concepts
  here to gather your feedback and refine them to make sure to meet our customers' needs. Feel free to explore these
  features and let us know what you like or dislike. Your input helps us improve. Let us Innovate Farming Together!
</p>
<img src="images/home_maturity.png" alt="Welcome" />
<p>
  When you share your feedback with us, you nurture our ideas, allowing them to grow into valuable features and
  products.
</p>
<div class="actions">
  <app-button variant="basic-primary" (buttonClick)="openModal()"> Learn more about maturity levels </app-button>
</div>
<app-notification />
