@if (!submitted) {
  <div class="title">Innovate with us</div>
  <p>
    We invite you to share your ideas with us. Please take a few moments to let us know what you like, dislike, and if
    you have any new ideas for us to explore.
  </p>
  <form [formGroup]="form" (ngSubmit)="submitFeedback()">
    <div>
      <label for="ideas"></label>
      <textarea [formControlName]="formControlName" id="ideas" rows="5"></textarea>
    </div>
    <div class="email">
      <div>
        <b>E-MAIL CONTACT</b><br />
        <i>
          In case we need more information, we would like to contact you on the e-mail address you gave us when signing
          up.
        </i>
      </div>
      <div class="email-inner">
        <input [formControlName]="'consent'" type="checkbox" id="email" />
        <label for="email">By ticking this box you allow us to get in contact with you by e-mail.</label>
      </div>
    </div>
    <div class="submit"><app-button type="submit" variant="primary">Submit your feedback</app-button></div>
  </form>
} @else {
  <div class="submitted">
    <div class="content">
      <div class="title">Thank you for your feedback!</div>
      <div>Your feedback has been submitted successfully.</div>
    </div>
    <div class="actions">
      <app-button
        [ngStyle]="{ width: showCloseButton() ? 'initial' : '100%' }"
        (click)="submitted = false"
        variant="basic-primary"
        >Submit more feedback</app-button
      >
      @if (showCloseButton()) {
        <app-button (click)="closeModal()" variant="primary">close</app-button>
      }
    </div>
  </div>
}
