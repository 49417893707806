import { Component } from '@angular/core';
import { ModalService } from '../../../state/modal/modal.service';
import { FeatureInfoComponent } from '../feature-info/feature-info.component';
import { NotificationComponent } from '../notification/notification.component';
import { ButtonComponent } from '../../../components/button/button.component';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [ButtonComponent, NotificationComponent],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.css',
})
export class WelcomeComponent {
  constructor(private modalService: ModalService) {}
  openModal() {
    this.modalService.openModal(FeatureInfoComponent);
  }
}
