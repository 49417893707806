import { Component, input } from '@angular/core';

export type RibbonType = 'beta' | 'idea' | 'prototype' | 'product';

@Component({
  selector: 'app-ribbon',
  standalone: true,
  imports: [],
  templateUrl: './ribbon.component.html',
  styleUrl: './ribbon.component.css',
})
export class RibbonComponent {
  public type = input.required<RibbonType>();
}
