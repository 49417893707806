@let currentPage = currentPage$ | async;
<app-header [isOnLandingPage]="currentPage === '/'" />
<div class="content">
  <app-breadcrumbs />
  <router-outlet />
</div>
@if (currentPage === "/") {
  <app-footer />
}
@if (currentPage !== "/" && !currentPage?.startsWith("/admin")) {
  <app-feedback-fab />
}
