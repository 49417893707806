@if (url()) {
  <app-metrics [metrics]="kpis" />
  <iframe #myIframe [src]="safeUrl()" (load)="handleLoad(myIframe)" [ngStyle]="{ opacity: isLoaded ? 1 : 0 }"></iframe>
  @if (treatments.length) {
    <div class="kpi-card flex-grow">
      <div class="title">Application</div>
      <div class="treatment">
        <table>
          <tr>
            <th></th>
            <th class="left">Tank number</th>
            <th>Application rate</th>
            <th>Adjustment</th>
            <th>Tank usage</th>
          </tr>

          @for (treatment of treatments; track treatment.id) {
            <tr>
              <td class="color"><div [ngStyle]="{ borderLeftColor: treatment.color }"></div></td>
              <td class="left">{{ treatment.tank }}</td>
              <td>{{ treatment.rate }}</td>
              <td>{{ treatment.adjustment }}</td>
              <td>{{ treatment.usage }}</td>
            </tr>
          }
        </table>
      </div>
    </div>
  }
} @else {
  <div class="placeholder">
    <app-icon icon="map" />
    <div>Please select a configuration above</div>
  </div>
}
