import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from '../../../api/bff/backend.service';
import { FeatureType, Feedback } from '@shared/interfaces/feedback';
import { CardComponent } from '../../../components/card/card.component';
import { Observable, of, take } from 'rxjs';
import { AsyncPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Angular2SmartTableModule, Settings } from 'angular2-smart-table';
import { FeedbackFilterComponent } from './feedback-filter/feedback-filter.component';

interface FeedbackData {
  [x: string]: string | number | boolean | string[];
}
@Component({
  selector: 'app-feedback-page',
  standalone: true,
  imports: [CardComponent, AsyncPipe, Angular2SmartTableModule, DecimalPipe, FeedbackFilterComponent],
  providers: [DatePipe],
  templateUrl: './feedback-page.component.html',
  styleUrl: './feedback-page.component.css',
})
export class FeedbackPageComponent implements OnInit {
  protected feedbackPromise: Observable<Feedback[]> = of([]);
  protected settings: Settings | undefined;
  protected data: FeedbackData[] = [];
  protected rating = 0;

  constructor(
    private route: ActivatedRoute,
    private backend: BackendService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    const feature = (this.route.snapshot.paramMap.get('feature') || 'General') as FeatureType;
    this.feedbackPromise = this.backend.getFeedbackByFeature(feature);
    this.feedbackPromise.pipe(take(1)).subscribe((feedback) => {
      this.settings = {
        hideable: true,
        actions: false,
        columns: {
          userEmail: {
            title: 'User',
            type: 'html',
            valuePrepareFunction: (value) => {
              const parsedValue = JSON.parse(value[1]);
              const title = Object.entries(parsedValue)
                .map(([key, val]) => `${key}: ${val}`)
                .join('\n');
              return `<span title="${title}">${value[0]}</span>`;
            },
          },
          createdAt: { title: 'Date', valuePrepareFunction: (date) => this.datePipe.transform(date, 'short') || '' },
          consent: { title: 'Consent', hide: true },
          context: { title: 'Context', hide: true },
        },
      };

      // Add rating if it exists (-1 is used as a placeholder for no rating)
      if (feedback[0].rating >= 0) {
        this.settings.columns['rating'] = { title: 'Rating' };
      }

      // Takes about 1ms per 10k questions. If this gets too slow, we need to optimize this.
      const uniqueQuestions = new Set<string>();
      feedback.forEach((item) => item.feedback.forEach((feedbackItem) => uniqueQuestions.add(feedbackItem.question)));
      uniqueQuestions.forEach((question) => {
        this.settings!.columns[question] = {
          title: question,
          hide: true,
        };
      });

      this.data = feedback.map((item) => {
        const data: FeedbackData = {
          userEmail: [item.user.email, JSON.stringify(item.user)],
          createdAt: item.createdAt!,
          consent: item.consent.hasConsented!,
          context: item.context,
          rating: item.rating,
        };
        item.feedback.forEach((feedbackItem) => {
          data[feedbackItem.question] = feedbackItem.answer;
        });
        return data;
      });

      this.rating = feedback.reduce((acc, item) => acc + item.rating, 0) / feedback.length;
    });
  }
}
