import { Component } from '@angular/core';
import { ButtonComponent } from '../../../../components/button/button.component';

@Component({
  selector: 'app-feedback-filter',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './feedback-filter.component.html',
  styleUrl: './feedback-filter.component.css',
})
export class FeedbackFilterComponent {
  public filter(event: MouseEvent) {
    event.stopPropagation();
    const dropdown = document.getElementsByTagName('angular2-smart-table-tags-list')[0] as HTMLDivElement;
    dropdown.style.display = 'block';
    const dropdownInner = dropdown.children[0] as HTMLDivElement;
    const closeDropdown = (event: MouseEvent) => {
      if (!dropdownInner.contains(event.target as Node)) {
        dropdown.style.display = 'none';
        document.removeEventListener('click', closeDropdown);
        document.removeEventListener('keydown', closeOnEscape);
      }
    };

    const closeOnEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        dropdown.style.display = 'none';
        document.removeEventListener('click', closeDropdown);
        document.removeEventListener('keydown', closeOnEscape);
      }
    };

    document.addEventListener('click', closeDropdown);
    document.addEventListener('keydown', closeOnEscape);
  }
}
