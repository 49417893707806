import { APP_INITIALIZER, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsServiceInterface } from './analytics.service.interface';

interface ExtendedWindow {
  sa_event: (name: string, options: undefined, callback: () => void) => void;
  __karma__?: void;
}
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements AnalyticsServiceInterface {
  private scriptLoaded = false;
  private scriptLoadPromise: Promise<void>;

  constructor() {
    this.scriptLoadPromise = new Promise<void>((resolve, reject) => {
      if (this.isTestEnvironment()) {
        return resolve();
      }
      resolve();
      if (this.scriptLoaded) {
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = environment.analytics.script;
        script.async = true;
        script.defer = true;
        script.setAttribute('data-hostname', environment.analytics.hostName);
        script.onload = () => {
          this.scriptLoaded = true;
          resolve();
        };
        script.onerror = () => reject(new Error('Failed to load the analytics script'));
        document.body.appendChild(script);
      }
    });
  }

  public isTestEnvironment(): boolean {
    return !!(window as unknown as ExtendedWindow).__karma__;
  }

  public ensureScriptLoaded(): Promise<void> {
    return this.scriptLoadPromise;
  }

  public async trackEvent(eventName: string): Promise<void> {
    await this.ensureScriptLoaded();
    await new Promise<void>((resolve) =>
      (window as unknown as ExtendedWindow).sa_event(eventName, undefined, () => resolve()),
    );
  }
}

export const provideAnalytics = {
  provide: APP_INITIALIZER,
  useFactory: (analyticsService: AnalyticsService) => () => analyticsService.ensureScriptLoaded(),
  deps: [AnalyticsService],
  multi: true,
};
