import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { AnalyticsData, BackendServiceInterface } from './backend.service.interface';
import { FeatureType, Feedback, FeedbackCreate, FeedbackStatistic } from '@shared/interfaces/feedback';
import { User } from '@shared/interfaces/user';
import { analyticsStub } from './backend-mock-data';

@Injectable({ providedIn: 'root' })
export class BackendService implements BackendServiceInterface {
  constructor(private httpClient: HttpClient) {}

  getHealth(): Observable<string> {
    return this.httpClient.get<string>(`${environment.backend}/health`);
  }

  getUser(): Observable<User> {
    return this.httpClient.get<User>(`${environment.backend}/user`);
  }

  putUser(user: User): Observable<User> {
    return this.httpClient.put<User>(`${environment.backend}/user`, user);
  }

  // TODO: Implement real call;
  getAnalytics(): Observable<AnalyticsData> {
    return of(analyticsStub);
  }

  getFeedback(): Observable<Feedback[]> {
    return this.httpClient.get<Feedback[]>(`${environment.backend}/feedback`);
  }

  getFeedbackStatistics(): Observable<FeedbackStatistic[]> {
    return this.httpClient.get<FeedbackStatistic[]>(`${environment.backend}/feedback/statistics`);
  }

  getFeedbackByFeature(feature: FeatureType): Observable<Feedback[]> {
    return this.httpClient.get<Feedback[]>(`${environment.backend}/feedback/feature/${feature}`);
  }

  postFeedback(data: FeedbackCreate): Observable<Feedback> {
    return this.httpClient.post<Feedback>(`${environment.backend}/feedback`, data);
  }
}
