@let feedback = feedbackPromise | async;
@if (settings && feedback) {
  <app-card>
    <div class="header">
      <img src="icons/idea.svg" alt="feedback" />
      <h2>{{ feedback[0].feature }}</h2>
      <app-feedback-filter />
    </div>
    <p>
      Answers: <b>{{ feedback.length }}</b>
      @if (settings.columns["rating"]) {
        <span>
          , Score: <b>{{ rating | number: "1.0-2" }}</b>
        </span>
      }
    </p>
    <angular2-smart-table class="table" [settings]="settings" [source]="data"></angular2-smart-table>
  </app-card>
}
