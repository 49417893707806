import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OSMResponse, PortalServiceInterface } from './portal.service.interface';
import { environment } from '../../../environments/environment';
import { OSM_RESPONSE_MOCK } from './mock';

@Injectable({
  providedIn: 'root',
})
export class PortalService implements PortalServiceInterface {
  constructor(private http: HttpClient) {}

  uploadOSMFile(file: File): Observable<OSMResponse> {
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    if (!fileExtension || (fileExtension !== 'xml' && fileExtension !== 'zip')) {
      throw new Error('Unsupported file type');
    }

    const headers = new HttpHeaders({
      'Content-Type': `application/${fileExtension}`,
    });

    return this.http.post<OSMResponse>(environment.portal.osm, file, { headers });
  }

  exampleOSMFile(): Observable<OSMResponse> {
    return of(OSM_RESPONSE_MOCK);
  }

  downloadOSMFile(file: File): Observable<Blob> {
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    if (!fileExtension || (fileExtension !== 'xml' && fileExtension !== 'zip')) {
      throw new Error('Unsupported file type');
    }

    const headers = new HttpHeaders({
      'Content-Type': `application/${fileExtension}`,
      Accept: `application/zip`,
    });

    return this.http.post<Blob>(environment.portal.osm, file, { headers, responseType: 'blob' as 'json' });
  }

  downloadExampleOSMFile(): Observable<Blob> {
    return this.http.get('/osm/osm.zip', { responseType: 'blob' });
  }
}
