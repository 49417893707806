import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FeedbackFabComponent } from '../components/feedback-fab/feedback-fab.component';
import { BreadcrumbsComponent } from '../components/breadcrumbs/breadcrumbs.component';
import { filter, map, Observable, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterModule, HeaderComponent, FeedbackFabComponent, FooterComponent, BreadcrumbsComponent, AsyncPipe],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css',
})
export class LayoutComponent {
  protected currentPage$: Observable<string>;

  constructor(private router: Router) {
    this.currentPage$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      tap((val) => console.log('isLandingPage', val.urlAfterRedirects)),
      map((event: NavigationEnd) => event.urlAfterRedirects),
    );
  }
}
