<h2>From idea to product</h2>
<p>
  Your feedback helps our ideas grow into valuable features and products, like a seed growing into a mature plant,
  ensuring they meet users' needs and support their daily work.
</p>
<div class="levels-wrapper">
  <div>
    <div class="level">
      <img src="maturity-level/idea.png" alt="Idea" />
      <p>A concept or description of a solution to a problem we would like to get your feedback on.</p>
    </div>
    <div class="level">
      <img src="maturity-level/prototype.png" alt="Prototype" />
      <p>An early model of a solution. Your feedback will help us refine and improve the final solution.</p>
    </div>
    <div class="level">
      <img src="maturity-level/beta.png" alt="Beta" />
      <p>Working feature ready for testing. Please let us know about your experience when using it.</p>
    </div>
    <div class="level">
      <img src="maturity-level/product.png" alt="Product" />
      <p>Working feature ready for testing. Please let us know about your experience when using it.</p>
    </div>
  </div>
  <img src="images/home_maturity.png" alt="Welcome" />
</div>
