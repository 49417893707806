import { Feedback, FeedbackCreate, FeedbackStatistic } from '@shared/interfaces/feedback';
import { User } from '@shared/interfaces/user';
import { AnalyticsData } from './backend.service.interface';

export const feedbackStub: Feedback = {
  _id: '1',
  userId: '1',
  createdAt: new Date().toDateString(),
  feature: 'General',
  context: 'Home',
  rating: 1,
  consent: { hasConsented: true, description: 'description' },
  feedback: [{ question: 'name', answer: ['value'] }],
  user: {
    _id: '1',
    username: '1234',
    email: 'dummy',
    isAdmin: true,
    country: 'Denmark',
    yearsInIndustry: '1-5',
    role: 'Other',
    eulaVersion: 1,
  },
};

export const feedbackCreateStub: FeedbackCreate = {
  feature: 'General',
  context: 'Home',
  rating: 1,
  consent: { hasConsented: true, description: 'description' },
  feedback: [{ question: 'name', answer: ['value'] }],
};

export const userStub: User = {
  _id: '1',
  username: 'ba9f8b9a-db03-4264-a226-8329c83b2b36',
  email: 'dummy',
  isAdmin: false,
  country: 'Denmark',
  yearsInIndustry: '1-5',
  role: 'Other',
  eulaVersion: 1,
};

export const analyticsStub: AnalyticsData = {
  visitors: {
    byCountry: {
      'United States of America': 100,
      Canada: 200,
      Denmark: 300,
      Germany: 400,
      France: 500,
      Italy: 600,
      Spain: 700,
      Japan: 800,
      Australia: 900,
      Brazil: 1000,
      India: 1100,
      China: 1200,
      Russia: 1300,
      'South Africa': 1400,
      Mexico: 1500,
      Argentina: 1600,
      'United Kingdom': 1700,
      'South Korea': 1800,
      'Saudi Arabia': 1900,
      'New Zealand': 2000,
      Netherlands: 2100,
      Sweden: 2200,
      Norway: 2300,
      Switzerland: 2400,
      Turkey: 2500,
    },
    byDate: {
      '07-01': 822,
      '07-02': 109,
      '07-03': 973,
      '07-04': 460,
      '07-05': 344,
      '07-06': 193,
      '07-07': 209,
      '07-08': 679,
      '07-09': 351,
      '07-10': 920,
      '07-11': 793,
      '07-12': 504,
      '07-13': 352,
      '07-14': 125,
      '07-15': 447,
      '07-16': 893,
      '07-17': 701,
      '07-18': 804,
      '07-19': 625,
      '07-20': 603,
      '07-21': 972,
      '07-22': 284,
      '07-23': 680,
      '07-24': 199,
      '07-25': 953,
      '07-26': 409,
      '07-27': 403,
      '07-28': 490,
      '07-29': 263,
      '07-30': 846,
      '07-31': 121,
    },
  },
};

export const feedbackStatisticStub: FeedbackStatistic[] = [
  {
    feature: 'General',
    count: 1,
    rating: 1,
  },
];
