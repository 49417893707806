import { Component } from '@angular/core';
import { RibbonComponent } from '../../../components/ribbon/ribbon.component';

@Component({
  selector: 'app-feature-info',
  standalone: true,
  imports: [RibbonComponent],
  templateUrl: './feature-info.component.html',
  styleUrl: './feature-info.component.css',
})
export class FeatureInfoComponent {}
